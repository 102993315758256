@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
    --amplify-fonts-default-variable: "Open Sans";
    --amplify-fonts-default-static: "Open Sans";
    --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
    --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
    --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
    --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
    --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
    --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
    --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
    --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
    --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
    --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
    --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
    --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
    --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
    --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
    --amplify-colors-radii-small: 0;
    --amplify-colors-radii-medium: 2px;
    --amplify-colors-radii-large: 4px;
    --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
    --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
    --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
    --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
    --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

    --amplify-radii-small: 0;
    --amplify-radii-medium: 0;
    --amplify-radii-large: 0;
    --amplify-space-small: 1rem;
    --amplify-space-medium: 1.5rem;
    --amplify-space-large: 2rem;
    --amplify-border-widths-small: 2px;
    --amplify-border-widths-medium: 4px;
    --amplify-border-widths-large: 8px;
}

.tab-navigation {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-navigation button {
    cursor: pointer;
    padding: 10px 20px;
    background-color: lightgray;
    border: none;
    border-radius: 5px;
}

.tab-navigation button.active {
    background-color: blue;
    color: white;
}

.tab-content {
    /* Add your styling for the content area */
}

.custom-tab {
    width: 150px;
    /* Adjust this value as needed */
}

.nav-link {
    font-weight: bold;
    /* Makes the text bold */
    color: #333;
    /* Example color, change as needed */
}

.user-info {
    position: relative;
    display: inline-block;
}

.user-name {
    visibility: hidden;
    opacity: 0;
    width: 120px;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    min-width: 200px;
    white-space: nowrap;
}

.user-info:hover .user-name {
    visibility: visible;
    opacity: 1;
}

.show-model {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 1000;
}

.footer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 0px;
    background-color: #343a40;
    color: #f8f9fa;
    position: fixed;
    bottom: 0;
    width: 100%;
}


.modal-backdrop-confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-backdrop-confirm.show {
    opacity: 1;
}

@keyframes fadeInDown {
    from {
        transform: translateY(-100px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal.fade.show .modal-dialog {
    animation: fadeInDown 0.3s ease-in-out;
}